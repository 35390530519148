import { Container } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import InvoiceForm from './Components/InvoiceForm';
import { Component } from 'react';

class App extends Component{
  render(){
  return (
    <div className="App d-flex flex-column align-items-center justify-content-center w-100">
        <Container>
          <InvoiceForm/>
        </Container>
    </div>
  );
}
}

export default App;
